import * as React from 'react';
import { Button, Text } from '@dreamplan/ui';
import clsx from 'clsx';
import { PageLinkType } from '../../../types/commonTypes';

const Arrow = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="72"
      height="147"
      viewBox="0 0 72 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(10)"
    >
      <path
        d="M19.0001 1.00006C54.9106 5.13669 107.068 97.9591 3.43678 136.107"
        stroke="#FAF33E"
        strokeDasharray="2 2"
      />
      <path d="M9.55098 123.755L1.39203 136.959L14.9626 141.49" stroke="#FAF33E" />
    </svg>
  );
};

const HeroLanding = (props: PageLinkType) => {
  const {
    contentSubHeader,
    contentHeader,
    contentDescription,
    video,
    buttonDescription,
    buttonLink,
    color,
  } = props;

  return (
    <section className="3xl:-mx-[238px] xs:mb-[200px] md:mb-[180px] lg:-mb-[110px] xl:mb-0">
      <div
        className={clsx(
          'flex flex-col gap-y-20 md:gap-y-24 lg:gap-y-0',
          'xs:h-[580px] px-8 text-black md:h-[700px] md:px-16 lg:mb-[250px] 2xl:px-32',
          `bg-${color}`,
          '-mx-[32px] lg:-mx-[96px] 2xl:-mx-[128px]',
        )}
      >
        <article className="mt-[88px] flex flex-col items-center justify-center gap-y-8 py-20 md:mt-[99px]">
          <div className="mb-16">
            <Text variant={'default'} tag="h2" className="py-0 text-center font-sans">
              {contentHeader}
            </Text>
            <Text
              variant={'h3'}
              className="py-0 text-center md:whitespace-pre-line md:text-4xl xl:text-5xl"
            >
              {contentSubHeader}
            </Text>
          </div>
          {!contentDescription || (
            <Text className="py-0 text-center font-sans lg:whitespace-pre-line">
              {contentDescription}
            </Text>
          )}
          <div>
            <Button asChild variant="primary">
              <a href={buttonLink}>{buttonDescription}</a>
            </Button>
            <Arrow className="absolute ml-80 hidden -translate-y-3/4 sm:block" />
          </div>
          <video src={video} controls className="mt-[20px] md:h-[400px] xl:h-[500px]" />
        </article>
      </div>
    </section>
  );
};

export default HeroLanding;
